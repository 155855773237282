import { ml } from "../../../../core/common/matrixlib";
import {
    IGlobalPrintFunctionParams,
    IPrintFunction,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { AttributePrimitive } from "./AttributePrimitive";
import { LinkPrimitive } from "./LinkPrimitive";
import { TitlePrimitive } from "./TitlePrimitive";

export type { IBreadcrumbsPrimitiveParams };
export { BreadcrumbPrimitive };

interface IBreadcrumbsPrimitiveParams {
    excludeItem?: boolean; // default:false.  whether to include the item itself
    showIDs?: boolean; // default:false.  whether to show the ids
    showLinks?: boolean; // default:false.  whether to show the ids as links to the live instance
    showLinksInside?: boolean; // default:false.  whether to show the ids as links inside the document
    rangeStart?: number; // default:0. return a sub-path, starting here. 0 is the first segment. Can be negative to count from the back, for example -1 will take the last element only
    rangeEnd?: number; // default:null end sub-path here. If not given will always take the whole path from the start
    separator?: string; // default " > " + separator to use
    class: string; // default:"bcrumbs". class for outermost container
    classBreadcrumb: string; // default:"bcrumb". class for each item
    classBreadcrumbSeparator: string; // default:"bcrumbSeparator". class separator between breadcrumbs
}

class BreadcrumbPrimitive implements IPrintFunction {
    static uid = "breadcrumbs";

    getGroup() {
        return "Item";
    }

    getHelp() {
        return `<h1>Renders the breadcrumbs of an item (all parent folders)</h1>
<p>Options</p>
<pre>
    excludeItem?:boolean // default:false.  whether to include the item itself
    showIDs?: boolean  // default:false.  whether to show the ids
    showLinks?: boolean  // default:false.  whether to show the ids as links to the live instance
    showLinksInside?: boolean  // default:false.  whether to show the ids as links inside the document
    rangeStart?:number // default:0. return a sub-path, starting here. 0 is the first segment. Can be negative to count from the back, for example -1 will take the last element only
    rangeEnd?:number // default:null end sub-path here. If not given will always take the whole path from the start
    separator?:string // default " > " + separator to use
    class:string // default:"bcrumbs". class for outermost container
    classBreadcrumb:string // default:"bcrumb". class for each item
    classBreadcrumbSeparator:string // default:"bcrumbSeparator". class separator between breadcrumbs
</pre>`;
    }

    getName() {
        return "Breadcrumbs of item";
    }

    private defaults: IBreadcrumbsPrimitiveParams = {
        excludeItem: false,
        showIDs: false,
        showLinks: false,
        showLinksInside: false,
        rangeStart: 0,
        separator: " > ",
        class: "bcrumbs",
        classBreadcrumb: "bcrumb",
        classBreadcrumbSeparator: "bcrumbSeparator",
    };

    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IBreadcrumbsPrimitiveParams>paramsIn;
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[BreadcrumbPrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[BreadcrumbPrimitive.uid],
            ...overwrites.section[BreadcrumbPrimitive.uid],
        });

        let folders: JQuery[] = [];
        {
            let parent = itemOrFolder.parent();
            while (parent[0].nodeName.toLowerCase() == "folder") {
                folders.push(parent);
                parent = parent.parent();
            }
        }
        folders.reverse();
        folders = params.rangeEnd
            ? folders.splice(params.rangeStart, params.rangeEnd)
            : folders.splice(params.rangeStart);

        let breadcrumbs: string[] = [];
        for (let folder of folders) {
            const breadcrumb = `<span class='${params.classBreadcrumb}'>${await this.toString(
                overwrites,
                params,
                folder[0].getAttribute("ref") || "",
                folder,
                mf,
                globals,
                possibleTargets,
                onError,
            )}</span>`;
            breadcrumbs.push(breadcrumb);
        }
        if (!params.excludeItem) {
            const breadcrumb = `<span class='${params.classBreadcrumb}'>${await this.toString(
                overwrites,
                params,
                itemOrFolderRef,
                itemOrFolder,
                mf,
                globals,
                possibleTargets,
                onError,
            )}</span>`;
            breadcrumbs.push(breadcrumb);
        }

        const fullpath = breadcrumbs.join(
            `<span class='${params.classBreadcrumbSeparator}'>${params.separator}</span>`,
        );
        return `<span class='${params.class}'>${fullpath}</span>`;
    }

    private async toString(
        overwrites: IGlobalPrintFunctionParams,
        params: IBreadcrumbsPrimitiveParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let str = "";
        if (params.showIDs) {
            str +=
                (await new AttributePrimitive().renderAsync(
                    overwrites,
                    { attributeName: "ref" },
                    itemOrFolderRef,
                    item,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                )) + " ";
        }
        if (params.showLinks) {
            str +=
                (await new LinkPrimitive().renderAsync(
                    overwrites,
                    { toAnchor: false },
                    itemOrFolderRef,
                    item,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                )) + " ";
        }
        if (params.showLinksInside) {
            str +=
                (await new LinkPrimitive().renderAsync(
                    overwrites,
                    { toAnchor: true },
                    itemOrFolderRef,
                    item,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                )) + " ";
        }
        str += await new TitlePrimitive().renderAsync(
            overwrites,
            {},
            itemOrFolderRef,
            item,
            mf,
            globals,
            possibleTargets,
            onError,
        );
        return str;
    }

    editParams(params: IBreadcrumbsPrimitiveParams, onUpdate: (newParams: IBreadcrumbsPrimitiveParams) => void) {
        let ui = $("<div>");

        let org = <IBreadcrumbsPrimitiveParams>ml.JSON.clone({ ...this.defaults, ...params });

        let numericUpdate = (newParams: IBreadcrumbsPrimitiveParams) => {
            console.log("Perform update check on ", newParams.rangeStart, newParams.rangeEnd);
            const start = newParams.rangeStart?.toString();
            const end = newParams.rangeEnd?.toString();

            // Set values to null if not numeric
            if (start) {
                const parsed = Number.parseInt(start);
                if (parsed) {
                    newParams.rangeStart = parsed;
                } else {
                    newParams.rangeStart = undefined;
                }
            } else {
                newParams.rangeStart = undefined;
            }
            if (end) {
                const parsed = Number.parseInt(end);
                if (parsed) {
                    newParams.rangeEnd = parsed;
                } else {
                    newParams.rangeEnd = undefined;
                }
            } else {
                newParams.rangeEnd = undefined;
            }
            console.log(newParams.rangeStart, newParams.rangeEnd);
            onUpdate(newParams);
        };

        // TODO: why do we need this conversion?
        const paramStringConverted = {
            ...org,
            rangeStart: org.rangeStart ? org.rangeStart.toString() : "0",
            rangeEnd: org.rangeEnd ? org.rangeEnd.toString() : org.rangeEnd,
        };

        ml.UI.addCheckbox(ui, "Exclude item itself", org, "excludeItem", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Show item IDs", org, "showIDs", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Show item IDs as links to project", org, "showLinks", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Show item IDs as links into document", org, "showLinksInside", () => {
            onUpdate(org);
        });
        ml.UI.addTextInput(
            ui,
            "Sub-range start, starts at 0 and can be negative to count from end (-1 == last element)",
            paramStringConverted,
            "rangeStart",
            () => {
                numericUpdate(org);
            },
        );
        ml.UI.addTextInput(
            ui,
            "Sub-range end, if empty will take up to end. Has to be larger than start.",
            paramStringConverted,
            "rangeEnd",
            () => {
                numericUpdate(org);
            },
        );
        ml.UI.addCheckbox(ui, "Show item IDs as links into document", org, "showLinksInside", () => {
            onUpdate(org);
        });
        ml.UI.addTextInput(ui, "Separator between crumbs", org, "separator", () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(BreadcrumbPrimitive.uid, new BreadcrumbPrimitive());

import { ml } from "../../../core/common/matrixlib";
import { IStringMap } from "../../../core/globals";
import { IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintItemIteratorParams, IPrintItemIterator } from "../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { FieldContent } from "../functions/fields/FieldContent";
import { PrintProcessor } from "../PrintProcessor";
import { FieldIterator } from "./FieldIterator";

export type { IRowIteratorParams };
export { TableRowIterator };

interface IRowIteratorParams extends IPrintItemIteratorParams {
    fieldName: string; // name of the table field
    showRowsMatching?: IStringMap; // allows to hide row if a specific column value match the a REGEX
}

class TableRowIterator implements IPrintItemIterator {
    worksOnItem = true;
    worksOnFolder = false;
    folderIterator = false;
    traceIterator = false;
    tableRowIterator = true;

    static uid = "row_iterator";

    getHelp() {
        return `<h1>Iterator over rows of table</h1>
        <p>fieldName: name of the field with the table</p><p>showRowsMatching?:IStringMap, // allows to hide row if a specific column value match the a REGEX</p>
        `;
    }

    getValidation(): null {
        return null;
    }

    getName() {
        return "Table Row Iterator";
    }

    async iterate(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintItemIteratorParams,
        itemOrFolder: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IRowIteratorParams>paramsIn;
        if (itemOrFolder.indexOf("F-") == 0) {
            onError(`this needs to be a item id: "${itemOrFolder}"`);
            return [];
        }
        if (!paramsCaller.fieldName) {
            onError("Table row iterator needs the name of the table");
            return [];
        }

        const item = globals.itemMap[itemOrFolder];
        if (!item) {
            onError(`cannot iterate over table in folders "${itemOrFolder}"`);
            return [];
        }

        const paramsDefaults: IRowIteratorParams = {
            fieldName: "",
            showRowsMatching: {},
        };

        const params = <IRowIteratorParams>ml.JSON.clone({
            ...paramsDefaults,
            ...overwrites.customer[FieldContent.uid],
            ...paramsCaller,
            ...overwrites.project[FieldContent.uid],
            ...overwrites.section[FieldContent.uid],
        });

        // get the table
        let iterator = PrintProcessor.getFieldIterator(FieldIterator.uid);
        let fields = iterator
            ? await iterator.iterate(
                  overwrites,
                  { showNames: [params.fieldName] },
                  itemOrFolder,
                  mf,
                  globals,
                  possibleTargets,
                  onError,
              )
            : null;
        if (fields && fields.length) {
            let itemId = itemOrFolder;
            let category = ml.Item.parseRef(itemId).type;
            let xtcColumns = $(`setting_xml[key='xtc_config'] render ${category} columns`, mf);
            let tableColumns = $(`field_def[field_id=${fields[0].fieldId}] param_xml columns`, mf);
            let columnDefinitions = tableColumns.length ? tableColumns : xtcColumns;

            let children: string[] = [];

            let table = fields[0].jsonValue;
            if (table) {
                for (let row = 0; row < table.length; row++) {
                    let hide = false;

                    $.each(columnDefinitions, function (colIdx, colDef) {
                        let colName = $(`field`, colDef).text();
                        let colVal = table[row][colName];
                        if (params.showRowsMatching && params.showRowsMatching[colName] != null) {
                            let regex = new RegExp(params.showRowsMatching[colName]);
                            if (!regex.test(colVal)) {
                                hide = true;
                            }
                        }
                    });
                    if (!hide) children.push("" + row);
                }
            }

            return children;
        } else {
            // nothing to render -> field does not exist
            return [];
        }
    }
}

PrintProcessor.addItemIterator(TableRowIterator.uid, new TableRowIterator());

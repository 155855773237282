import { ml } from "../../../core/common/matrixlib";
import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../PrintProcessor";
import { ItemHasTraces } from "./ItemHasTraces";

export type { IFolderHasItemsConditionParams };
export { FolderHasItems };

interface IFolderHasItemsConditionParams {
    iterator: string; // iterator to use
    negate: boolean;
}

class FolderHasItems implements IConditionFunction {
    itemOrFolder = true;

    static uid = "folderHasChildren";

    getHelp() {
        let iterators = PrintProcessor.getItemIteratorsDropdown(false, true, false);
        let iteratorsText = iterators.map((itdd) => `    //${itdd.id}: ${itdd.label}`).join("\n");
        return `<h1>FOLDER: Render if folder has children</h1>
<p>Options
<pre>
    iterator:string // iterator to use
${iteratorsText}
    negate: boolean // negate filter
</pre>`;
    }

    getName() {
        return "FOLDER: Render if folder has children";
    }

    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IFolderHasItemsConditionParams,
        itemOrFolderRef: string,
        object: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsDefaults: IFolderHasItemsConditionParams = {
            iterator: "children", // default down
            negate: false,
        };

        const params = ml.JSON.clone({
            ...paramsDefaults,
            ...overwrites.customer[ItemHasTraces.uid],
            ...paramsCaller,
            ...overwrites.project[ItemHasTraces.uid],
            ...overwrites.section[ItemHasTraces.uid],
        });

        let iterator = PrintProcessor.getItemIterator(params.iterator);
        if (!iterator) {
            onError(`"iterator does not exist "${params.iterator}".`);
            return false;
        }
        let children = await iterator.iterate(
            overwrites,
            params,
            itemOrFolderRef,
            mf,
            globals,
            possibleTargets,
            onError,
        );
        if (children.length) {
            return true;
        }

        return false;
    }
}

PrintProcessor.addConditionFunction(FolderHasItems.uid, new FolderHasItems());
